/* src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-slide {
  display: flex;
  animation: slide 20s linear infinite;
}

.min-w-full {
  min-width: 100%;
}
